import { twMerge } from 'tailwind-merge';
import { forwardRef } from 'react';

export type SpinnerProps = {
  className?: string;
};

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(function Spinner({ className }, ref) {
  return (
    <div
      ref={ref}
      className={twMerge(
        'inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]',
        className,
      )}
      role="status"
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
    </div>
  );
});
