import { Button as HeadlessButton, type ButtonProps as HeadlessButtonProps } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';
import { TouchTarget } from './Button';
import { Link } from './Link';

const colors = {
  red: 'bg-red-500/15 text-red-700 group-data-[hover]:bg-red-500/25 dark:bg-red-500/10 dark:text-red-400 dark:group-data-[hover]:bg-red-500/20',
  orange:
    'bg-orange-500/15 text-orange-700 group-data-[hover]:bg-orange-500/25 dark:bg-orange-500/10 dark:text-orange-400 dark:group-data-[hover]:bg-orange-500/20',
  yellow:
    'bg-yellow-400/20 text-yellow-700 group-data-[hover]:bg-yellow-400/30 dark:bg-yellow-400/10 dark:text-yellow-300 dark:group-data-[hover]:bg-yellow-400/15',
  lightGreen:
    'bg-light-green-400/20 text-light-green-700 group-data-[hover]:bg-light-green-400/30 dark:bg-light-green-400/10 dark:text-light-green-300 dark:group-data-[hover]:bg-light-green-400/15',
  green:
    'bg-green-500/15 text-green-700 group-data-[hover]:bg-green-500/25 dark:bg-green-500/10 dark:text-green-400 dark:group-data-[hover]:bg-green-500/20',
  teal: 'bg-teal-500/15 text-teal-700 group-data-[hover]:bg-teal-500/25 dark:bg-teal-500/10 dark:text-teal-300 dark:group-data-[hover]:bg-teal-500/20',
  cyan: 'bg-cyan-400/20 text-cyan-700 group-data-[hover]:bg-cyan-400/30 dark:bg-cyan-400/10 dark:text-cyan-300 dark:group-data-[hover]:bg-cyan-400/15',
  brightBlue:
    'bg-bright-blue-7/15 text-bright-blue-10 group-data-[hover]:bg-bright-blue-7/25 dark:bg-bright-blue-7/10 dark:text-bright-blue-5 dark:group-data-[hover]:bg-bright-blue-7/20',
  moodysBlue:
    'bg-moodys-blue-10/15 text-moodys-blue-12 group-data-[hover]:bg-moodys-blue-10/25 dark:text-moodys-blue-9 dark:group-data-[hover]:bg-moodys-blue-10/25',
  darkBlue:
    'bg-dark-blue-500/15 text-dark-blue-700 group-data-[hover]:bg-dark-blue-500/25 dark:text-dark-blue-400 dark:group-data-[hover]:bg-dark-blue-500/20',
  purple:
    'bg-purple-500/15 text-purple-700 group-data-[hover]:bg-purple-500/25 dark:text-purple-400 dark:group-data-[hover]:bg-purple-500/20',
  magenta:
    'bg-magenta-400/15 text-magenta-700 group-data-[hover]:bg-magenta-400/25 dark:bg-magenta-400/10 dark:text-magenta-400 dark:group-data-[hover]:bg-magenta-400/20',
  gray: 'bg-gray-8/10 text-gray-10 group-data-[hover]:bg-gray-8/20 dark:bg-white/5 dark:text-gray-4 dark:group-data-[hover]:bg-white/10',
};

type BadgeProps = { color?: keyof typeof colors };

export function Badge({
  color = 'brightBlue',
  className,
  ...props
}: BadgeProps & React.ComponentPropsWithoutRef<'span'>) {
  return (
    <span
      {...props}
      className={clsx(
        className,
        'inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm/5 font-medium sm:text-xs/5 forced-colors:outline',
        colors[color],
      )}
    />
  );
}

export const BadgeButton = React.forwardRef(function BadgeButton(
  {
    color = 'brightBlue',
    className,
    children,
    ...props
  }: BadgeProps & { children: React.ReactNode } & (HeadlessButtonProps | React.ComponentPropsWithoutRef<typeof Link>),
  ref: React.ForwardedRef<HTMLElement>,
) {
  let classes = clsx(
    className,
    'group relative inline-flex rounded-md focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-blue-500',
  );

  return 'href' in props ? (
    <Link {...props} className={classes} ref={ref as React.ForwardedRef<HTMLAnchorElement>}>
      <TouchTarget>
        <Badge color={color}>{children}</Badge>
      </TouchTarget>
    </Link>
  ) : (
    <HeadlessButton {...props} className={classes} ref={ref}>
      <TouchTarget>
        <Badge color={color}>{children}</Badge>
      </TouchTarget>
    </HeadlessButton>
  );
});
